import { Box } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import * as api from '../../api/calls';
import { ModioTable } from './ModioTable';

export default function GameModesTable() {
    const [data, setData] = useState(['Loading...']);

    useEffect(() => {
        const fetchData = async () => {
            const gameModesJSON = await api.getAllGameModes();
            return gameModesJSON;
        };
        setData([{ name: 'Loading...' }]);
        fetchData().then(r => setData(r));
    },[]);

    return (
        <Box>
                <p>These gamemodes are available for all horde servers. Use the "UGC" ID below to select the gamemode in RCON</p>

            <ModioTable data={data} setData={setData}></ModioTable>
        </Box>
    );
}
